<template>
  <div class="">
    <section>
      <div class="the_img">
        <v-img  class="image_" height="337" :src="API + theData.first_image"/>
      </div>
      <div class="details" v-html="theData.first_section"></div>
    </section>
    <section>
      <div class="the_img">
        <v-img class="image_" height="337" :src="API + theData.two_image"/>
      </div>
      <div class="details" v-html="theData.two_section"></div>
    </section>
    <section>
      <div class="the_img">
        <v-img class="image_" height="337" :src="API + theData.three_image"/>
      </div>
      <div class="details" v-html="theData.three_section"></div>
    </section>
  </div>
</template>

<script>
import {API} from "../env";
export default {
  props:['theData'],
  data() {
    return{
      API,
    }
  },
  created() {

  }
}
</script>

<style scoped>
/deep/p {
  margin-bottom: 0 !important;
}

.the_img {
  margin-top: 20px !important ;
}
</style>